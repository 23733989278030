import { isStaging } from './is-staging';

// Modules exported here will be included in bundle.js
const getPageLevelData = (pageType) => {
  let cbpgFunction = pageType;

  let queryElement = document.querySelector('.js-yext-query') || {};
  let query = queryElement.value || '';
  let cbpgSubFunction = '';
  let cbpgName = 'view';
  let cbsearchresults = '';
  let cbsearchquery = '';
  if (pageType === 'askacitizen') {
    cbpgSubFunction = 'questionnaire';
    cbpgName = 'complete';
  } else if (query) {
    cbpgFunction = 'search';
    cbpgSubFunction = 'results';
    cbpgName = 'complete';
    cbsearchquery = query;
    cbsearchresults = document.querySelectorAll('.js-resultItem').length ? 'Yes' : 'No';
  }

  var CBDL = CBDL || {};
  CBDL.pgInfo = CBDL.pageInfo || {};
  CBDL.pgInfo = {
    "cbcountry" : "us",
    "cblanguage" : "eng",
    "cbwebidentifier" : "marketing",
    "cbdevice" : window.device,
    "cbproduct" : "generic",
    "cbtype" : "consumer",
    "cbpgType" : "answers",
    "cbpgFunction" : cbpgFunction,
    "cbpgSubFunction" : cbpgSubFunction,
    "cbpgName" : cbpgName,
    "cbpgSubtype" : "customerservice",
    "cbpgProductType" : "",
    "cbsearchquery" : cbsearchquery,
    "cbsearchresults" : cbsearchresults,
    "cbbrand" : "cb",
    "cbgeoLocation" : window.myPosition
  };
  return CBDL;
}

const fireAnalytics = () => {
  _satellite.track('Answers_CBPageload');
}

const getEventLocked = (lock) => {
  return HitchhikerJS[lock];
}

const setEventLock = (lock) => {
  HitchhikerJS[lock] = true;
}

const releaseEventLock = (lock) => {
  HitchhikerJS[lock] = false;
}

const addClickLayer = () => {
  document.querySelectorAll('.js-yxt-navItem, .js-yext-submit, .js-yext-viewMoreLink, .js-HitchhikerCTA, .js-qaFormButton, .js-HitchhikerLinkStandard-titleLink').forEach((element) => {
    element.classList.add('cbensightenevent');
    element.setAttribute('cbdata-type', element.tagName);
  });

  const addEntityIdDataAttribute = (element, linkType) => {
    const eventOptions = element.dataset.eventoptions || {};
    const entityId = JSON.parse(eventOptions).entityId;
    element.setAttribute('cbdata-type', element.tagName);
    if (entityId) {
      element.setAttribute('cbdata-reason', `${entityId}-${linkType}`);
      element.setAttribute('cbdata-entityId', `${entityId}`);
    }
  }

  document.querySelectorAll('.js-HitchhikerCTA').forEach((el) => addEntityIdDataAttribute(el, 'link'));
  document.querySelectorAll('.js-Hitchhiker-title').forEach((el) => addEntityIdDataAttribute(el, 'title'));
}

const eventLocks = {
  PAGE_LOAD: 'PAGE_LOAD',
  ON_MOUNT: 'ON_MOUNT',
  ON_STATE_CHANGE: 'ON_STATE_CHANGE',
  ON_UPDATE: 'ON_UPDATE',
}

const importAnalytics = () => {
  let url = "https://assets.adobedtm.com/c6a477a8a7f5/566931d31a42/launch-f358725456d8.min.js";
  if (isStaging('answers_citizensbank_com.yextpages.net')) {
    url = "https://assets.adobedtm.com/c6a477a8a7f5/566931d31a42/launch-64aef95df5e1-development.min.js";
  }

  const utagScript  = document.createElement('script');
  utagScript.id = "adobeID";
  utagScript.src = url;
  utagScript.type = 'text/javascript';
  document.head.appendChild(utagScript);

  let preloadScript = document.querySelector('#adobeID');
  preloadScript.addEventListener('load', () => {
    const utagScriptBottom = document.createElement('script');
    utagScriptBottom.innerHTML = '_satellite.pageBottom();';
    utagScriptBottom.type = 'text/javascript';
    document.body.appendChild(utagScriptBottom);
  })
}

// Library calls onMount on page load, assume first call is always a page load
const fireAnalyticsWithChecks = () => {
  if (!getEventLocked(eventLocks.PAGE_LOAD)) {
    importAnalytics();
    setEventLock(eventLocks.PAGE_LOAD);
    return;
  }
  fireAnalytics();
}

export {
  getPageLevelData,
  fireAnalytics,
  fireAnalyticsWithChecks,
  addClickLayer,
  setEventLock,
  releaseEventLock,
  getEventLocked,
  eventLocks,
}