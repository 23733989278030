/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export function customPhoneLink(phone) {
    if (!phone) {
        return '';
    }
    return `tel:${phone}`;
}
  
export function customPhoneDisplay(phone) {
    if (!phone) {
      return '';
    }
    const phoneNumber = parsePhoneNumberFromString(phone)
    return phoneNumber ? phoneNumber.formatNational() : '';
  }
  